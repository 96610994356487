.disable-scrolling {
  position: fixed;
  width: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - calc(4 * var(--letter-size)));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-top: calc(1 * var(--letter-size));
  padding-bottom: calc(3 * var(--letter-size));
}

.App-link {
  color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grid {
  display : grid;
  grid-template-columns: repeat(var(--board-size), var(--letter-size));
  grid-template-rows: repeat(var(--board-size), var(--letter-size));

  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: calc(var(--letter-size) / 2);

  z-index: 1;
  perspective: 800px;
  transform-style: preserve-3d;
}

.selections {
  position: absolute;
  z-index: 0;
}

.selected {
  color: red;
  transition: color 0.8s ease;
}

.bokstav {
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: rotateX(180deg);
  opacity: 0.3;
}

.loading {
  opacity: 0.3;
}

.loaded {
  opacity: 0.3;
}

.celebrating {
  opacity: 1;
  transform: rotateX(0deg);
}

@keyframes flip {
  from {
    opacity: 0.3;
    transform: rotateX(180deg);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

.inner-bokstav {
  transform: rotateX(180deg);
}

@keyframes celebration {
  from {
    opacity: 1;
    transform: rotateX(0deg);
  }
  to {
    opacity: 1;
    transform: rotateX(-360deg);
  }
}

.selection-hidden {
  opacity: 0;
}

.selection-not-hidden {
  opacity: 1;
  transition: opacity 0.8s ease;
}

.selection-firkant {
  position: relative;
  opacity: 0.05;
  content: "";
  width: var(--letter-size);
  pointer-events: none;
  filter: blur(3px);

  transform-origin: 50% 0;
}

.selection-capsule {
  position: relative;
  background-color: white;
  content: "";
  width: 100%;
  height: 100%;
}

.fasit-container {
  position: relative;
  left: calc(-1 * var(--board-size) * var(--letter-size) / 2);
}

.fasit {
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  font-size: calc(var(--letter-size) / 2.9);

  position: absolute;
  margin-top: calc(var(--letter-size) / 1.5);
  margin-left: calc(var(--letter-size) / 3.3);
  width: calc(var(--board-size) * var(--letter-size));
  display: flex;
  flex-wrap: wrap;

  perspective: 800px;
  transform-style: preserve-3d;
}

.fasit>div {
  margin-right: calc(3 * var(--letter-size) / 5);
  margin-bottom: calc(var(--letter-size) / 5);
  opacity: 0;
  transform: rotateX(0deg);
  animation: spinFade 0.4s linear forwards;
}

@keyframes spinFade {
  50% {
    opacity: 0;
    transform: rotateX(-90deg)
  }
  to {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

.giveup-button {
  margin-top: calc(max(10vh, 100px));

  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  font-size: calc(var(--letter-size) / 2.9);

  cursor: pointer;

  padding: 14px;

  border: 0.5px solid white;
  border-radius: 30px;

  color: white;
  background-color: transparent;

  opacity: 0;
  transition: opacity 0.2s;
}

.giveup-button:active {
  opacity: 1;
  transition: opacity 0.1s;
}

.giveup-button.show {
  opacity: 0.6;
  transition: opacity 0.8s;
}

@media only screen and (max-width: 767px) {
  .grid {
    font-weight: 100;
    font-size: calc(var(--letter-size) / 1.5);
  }
  .fasit {
    font-weight: 100;
    font-size: calc(var(--letter-size) / 2.4);
  }
  .giveup-button {
    font-size: calc(var(--letter-size) / 2);
  }
}